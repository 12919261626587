.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    background: no-repeat center/cover url(../../images/page2/back_1.png);
}

.map {
    width: 2000px;
    height: 1338px;
    position: absolute;
    left: 0;
    top: 0;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
}

.iwenzi {
    /* background: no-repeat center/100% url(../../images/selectUser/wenzi.png); */
    width: 50vw;
    height: 8vw;
    left: 25vw;
    bottom: 40vw;
    text-align: center;
    position: absolute;
}

.iwenzi:before {
    font-size: 18px;
    font-family: UseFont;
    color: #036EA6;
    content: " 可以放大上下左右查看";
}

.button {
    background: no-repeat center/100% url(../../images/map/button_2.png);
    width: 58vw;
    height: 20vw;
    position: absolute;
    bottom: 12vw;
    left: 21vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: UseFont;
    animation: downInx 500ms;
}

.image {
    width: 2000px;
    height: 1338px;
    position: absolute;
    left: 0;
    top: 0;
}

.moveu {
    width: 60px;
    height: 148px;
    position: absolute;
    background: no-repeat top left/100% url(../../images/map/queen.png);
    left: -30px;
    top: -74px;
}

.king {
    width: 60px;
    height: 190px;
    background-image: url(../../images/map/king.png);
    top: -95px;
}

.bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    z-index: 10;
}

.endinfo {
    /* background: no-repeat top/100% url(../../images/acadmy/a-show.png); */
    width: 100vw;
    height: 102vw;
    position: absolute;
    left: 0;
    top: 50vw;
    animation: downIn 300ms linear;
}

@keyframes downInx {
    from {
        transform: translateY(100%);
    }
}

@keyframes downIn {
    from {
        transform: translateY(-10%);
        opacity: 0.5;
    }
}

@keyframes HideIn {
    from {
        opacity: 0;
    }

    50% {
        transform: translateY(-5%);
        opacity: 0;
    }
}

.answerstatus {
    width: 100vw;
    height: 80%;
    box-sizing: border-box;
    font-size: 4vw;
    font-family: UseFont;
    display: grid;
    grid-template-rows: 1fr 100px;
    position: relative;
}

.answersuccess {
    background: no-repeat center/100% url(../../images/introduce/dui.png);
    width: 90vw;
    height: 120vw;
    padding: 50vw 8vw 10vw;
    box-sizing: border-box;
    margin: 2vw auto 0;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: 1fr 50px;
    color: #FFE29D;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.302);
}

.answerfail {
    background: no-repeat center/100% url(../../images/introduce/cuo.png);
    width: 90vw;
    height: 120vw;
    padding: 30vw 2vw 10vw;
    box-sizing: border-box;
    margin: 2vw auto 0;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: 40px 80px 40px 1fr;
    color: #B48E6A ;
    font-size: 16px;
}

.answerTxt {
    text-align: center;
    color: #F7A31E;
    line-height: 1.2;
    align-self: start;
}

.answerTxt > div {
    font-size: 72px;
}

.failMessage {
    font-size: 30px;
    color: #935316;
}

.answersuccess>.img {
    background: no-repeat center center/100%;
    width: 40vw;
    height: 40vw;
}

.nextstatus {
    width: 220px;
    justify-self: center;
    align-self: center;
    color: #FFE29D;
    text-align: center;
}

.successbtn {
    background: no-repeat center/100% url(../../images/introduce/button_4.png);
    width: 220px;
    height: 50px;
    margin-bottom: 10px;
}

.failbtn {
    background: no-repeat center/100% url(../../images/introduce/button_5.png);
    width: 220px;
    height: 50px;
    margin-bottom: 10px;
}

.academyInfo {
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    font-size: 4vw;
    font-family: UseFont;
    display: grid;
    grid-template-rows: 1fr 92vw 50px;
    position: relative;
}

.academyInfo>.img {
    display: block;
    background: no-repeat center bottom/100%;
}

.descrption {
    background: no-repeat center/100% url(../../images/introduce/question.png);
    width: 90vw;
    height: 90vw;
    padding: 8vw;
    box-sizing: border-box;
    margin: 2vw auto 0;
    display: grid;
    grid-template-rows: 80px 1fr 50px;
    justify-items: center;
    align-items: center;
    color: #9F6B3A;
}

.closebtn {
    background: no-repeat center/100% url(../../images/introduce/cha.png);
    width: 34px;
    height: 34px;
    justify-self: center;
    align-self: center;
}

.answer>div {
    margin: 20px 0;
    background: no-repeat center/100% url(../../images/introduce/button_3.png);
    width: 70vw;
    font-size: 5vw;
    line-height: 10vw;
    text-align: center;
}

.jump {
    color: #A6966A;
    text-align: center;
}

.title {
    font-size: 4vw;
    line-height: 1.5;
    margin: 0;
    align-self: start;
}