.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    display: grid;
    grid-template-rows: 1fr 50px 150px;
    justify-items: center;
    align-items: center;
    background: no-repeat center/cover url(../../images/page2/back_1.png);
}

.bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    left: 0;
    top: 0;
    z-index: 10;
    font-family: UseFont;
    touch-action: none;
    color: #FFF;
}

.ubox {
    margin: 10px;
    justify-self: stretch;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img>img {
    display: block;
}

.btn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-family: UseFont;
    color: #333;
    font-size: 12px;
}

.btns {
    height: 100px;
    display: flex;
    justify-content: space-around;
    width: 90%;
}

.itemInfo {
    background: no-repeat center/100% url(../../images/shared/button_6.png);
    display: block;
    width: 30vw;
    height: 12vw;
}

.share {
    background: no-repeat center/100% url(../../images/shared/button_7.png);
    display: block;
    width: 30vw;
    height: 12vw;
}

.addBtn {
    background: no-repeat center/100% url(../../images/shared/button_8.png);
    display: block;
    width: 30vw;
    height: 12vw;
    margin-bottom: 5px;
    margin-bottom: 10px;
}

.itembg {
    position: absolute;
    width: 80vw;
    height: 170vw;
    padding: 25vw 2vw 10vw;
    box-sizing: border-box;
    overflow: hidden;
    touch-action: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: start;
    background: no-repeat center/100% url(../../images/page2/back_2.png);
}

.item {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #3D3D3D;
    font-family: UseFont;
}

.itemImg {
    background: no-repeat center/100%;
    display: block;
    width: 25vw;
    height: 25vw;
}

.itemShow {
    position: absolute;
    background: no-repeat center bottom/90%, no-repeat center/100%;
    display: block;
    width: 70vw;
    height: 75vw;
    box-sizing: border-box;
    padding: 60vw 0 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8vw;
}

.itemGroup {
    color: #97621D;
}

.cardIndex {
    position: absolute;
    transform: translateY(50vw);
    display: flex;
    width: 60vw;
    justify-content: space-around;
}

.cardIndex>div {
    display: block;
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    transform: scale(0.8);
}

.cardIndex>.selected {
    background: rgba(255, 255, 255, 0.8);
    transform: scale(1);
}

.closebtn {
    background: no-repeat center/100% url(../../images/introduce/cha.png);
    width: 34px;
    height: 34px;
    justify-self: center;
    align-self: center;
    position: absolute;
    transform: translate(350%, -350%);
    opacity: 0.5;
    z-index: 10;
}

.closebtn2 {
    width: 34px;
    height: 34px;
    justify-self: center;
    align-self: center;
    position: absolute;
    /* transform: translate(50%, 0%); */
    right: 4vw;
    top: 15vw;
    color: #A6966A;
    font-family: UseFont;
}