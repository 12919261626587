.page {
    position: absolute;
    background: no-repeat center/cover url(../../images/page2/back_1.png);
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100px 1fr 1fr 100px;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    touch-action: none;
}

/* .idengguang {
    background: no-repeat top/100% url(../../images/page2/selec.png);
    width: 45vw;
    height: 140vw;
    top: 0vw;
    left: 0vw;
    position: absolute;
    transform-origin: top center;
} */

.iicon {
    background: no-repeat center/100% url(../../images/page2/button_1.png);
    width: 58vw;
    height: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
}


.iwenzi {
    /* background: no-repeat center/100% url(../../images/selectUser/wenzi.png); */
    width: 50vw;
    height: 8vw;
    text-align: center;
}

.iwenzi:before { 
    font-size: 6vw;
    font-family: UseFont;
    color: #347C99;
    content: "请选择形象";
}

.ixiaoxi1 {
    background: no-repeat center/50vw url(../../images/page2/role_king.png);
    width: 50vw;
    height: 50vw;
    user-select: none;
    -webkit-user-select: none;
    touch-action: none;
    position: relative;
}

.ixiaoxi2 {
    background: no-repeat center/50vw url(../../images/page2/role_queen.png);
    width: 50vw;
    height: 50vw;
    user-select: none;
    -webkit-user-select: none;
    touch-action: none;
    position: relative;
}

.selected::after {
    content: "";
    background: no-repeat top/100% url(../../images/page2/selec.png);
    width: 54vw;
    height: 54vw;
    left: -2vw;
    top: -2vw;
    position: absolute;
}