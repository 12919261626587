.page {
    position: absolute;
    background: no-repeat center 70%/cover url(../../images/page1/shouye.png);
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

.home {
    width: 100vw;
    height: 198vw;
    top: 0;
    left: 0;
    font-size: 11vw;
    font-family: UseFont;
    text-align: center;
    color: #1A4482;
    position: absolute;
}

.xiahua {
    width: 16vw;
    height: 12vw;
    background: no-repeat center/100% url(../../images/page1/icon_xiahua.png);
    bottom: 2vw;
    left: 42vw;
    position: absolute;
}